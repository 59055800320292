<template>
    <div id="dc-edit-ticket">
        <b-button class="dc-button-back" @click="$router.back()"> <b-icon icon="arrow-left-circle-fill" aria-hidden="true"></b-icon> Back </b-button>

        <h3>Edit Ticket</h3>
        <hr />
        <b-form id="dc-form-container" @submit="onSubmitEditTicket">
            <div>
                <span>Ticket: </span>
                <span># {{ ticket.id }}</span>
            </div>

            <div>
                <span>Customer: </span>
                <span>{{ ticket.customer.company }}</span>
            </div>

            <div>
                <span>Date: </span>
                <span>{{ ticket.createdAt }}</span>
            </div>

            <div>
                <span>Truck: </span>
                <span>{{ ticket.truck }}</span>
            </div>

            <b-form-group id="dc-quickbooksinvoice-container" label="Quickbooks Invoice #:">
                <b-form-input v-model="ticket.quickbooksInvoice" type="text" placeholder="Quickbooks Invoice"></b-form-input>
            </b-form-group>

            <b-button class="dc-button-primary" type="submit" variant="primary">Save</b-button>
        </b-form>
    </div>
</template>

<script>
import APICalls from "./APICalls";
import util from "./../../util/util";

export default {
    name: "EditTicket",
    data() {
        return {
            ticket: {
                customer: {},
            },
        };
    },
    methods: {
        onSubmitEditTicket(event) {
            event.preventDefault();
            this.editTicket(this.ticket);
        },
        async editTicket(params) {
            let id = this.$router.currentRoute.params.id;
            let response = await APICalls.editTicket(id, params);
            console.log(response);
            this.$toasted.show("Edit ticket success!", { position: "bottom-center", className: "dc-toast-success", duration: 6000 });
        },
        async getTicket() {
            let id = this.$router.currentRoute.params.id;
            let ticket = await APICalls.getTicket(id);
            this.ticket = ticket;
        },
    },
    watch: {
        ticket() {
            this.ticket.createdAt = util.formatTimestamp(this.ticket.createdAt);
        },
    },
    mounted() {
        this.getTicket();
    },
};
</script>

<style lang="scss">
#dc-edit-ticket {
    #dc-form-container {
        margin-top: 20px;

        > div {
            margin-bottom: 10px;
            span:nth-child(1) {
                display: inline-block;
                min-width: 200px;
                font-size: 22px;
            }

            span:nth-child(2) {
                display: inline-block;
                min-width: 400px;
                font-size: 22px;
            }
        }

        #dc-quickbooksinvoice-container {
            display: flex;
            min-width: 20%;
            font-size: 20px;
        }

        .dc-button-primary {
            width: 180px;
            margin-top: 10px;
        }
    }
}
</style>
